<template>
  <div>
    <el-form>
      <ul class="wholesale">
        <li v-for="(tier, index) in priceTiers" :key="index">
          <div class="border-b">{{ tier.name }}</div>
          <el-form-item >
            <el-input @change="handleStocksChange(index)"
              v-model="tier.price_f" type="number" :placeholder="$t('placeholder.defaultInput')"></el-input>
          </el-form-item>
        </li>
      </ul>
    </el-form>
  </div>
</template>

<script>
import { getWholesalePricingTiers } from '@/services/product.js'
export default {
  name: 'priceTier',
  props: {
    object: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modelValue: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      priceTiers: [],
      tierAttributes: []
    }
  },
  watch: {
    modelValue: {
      handler(val, oldVal) {
        console.log('000000000000000000', val.shop_item_stock_tier_prices_attributes)
        if (val != oldVal) {
          this.init()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.$nextTick(() => {
    //   this.init()
    // })
  },
  mounted() { },
  methods: {
    async init() {
      const tiers = await getWholesalePricingTiers()
      this.priceTiers = tiers.data.wholesale_pricing_tiers
      if (this.modelValue.shop_item_stock_tier_prices_attributes instanceof Array  && this.modelValue.shop_item_stock_tier_prices_attributes.length > 0) {
        this.priceTiers.forEach(tier => {
          this.modelValue.shop_item_stock_tier_prices_attributes.map(val => {
            if (tier.id === val.wholesale_pricing_tier_id) {
              this.$set(tier, 'price_f', val.price_f)
              this.$set(tier, 'c_id', val.id)
            }
          })
          const c_tier = Object.assign({}, tier)
          c_tier.id = tier.c_id
          c_tier.wholesale_pricing_tier_id = tier.id
          delete c_tier.c_id
          delete c_tier.name
          this.tierAttributes.push(c_tier)
        })
      }
    },
    handleStocksChange(index) {
      this.tierAttributes[index] = {
        wholesale_pricing_tier_id: this.priceTiers[index].id,
        price_f: Number(this.priceTiers[index].price_f)
      }
      if (this.modelValue.shop_item_stock_tier_prices_attributes.length > 0) {
        this.tierAttributes[index].id = this.priceTiers[index].c_id
      }
      this.onCompelete()
    },
    onCompelete() {
      this.$emit('update:value', this.tierAttributes)
    }
  }
}
</script>

<style lang="scss" scope>
.wholesale {
	display: flex;
	border-right: 0;
	padding-inline-start: 0;
	margin: 0;
	li {
		list-style-type: none;
		width: 100px;
		border-left: 1px solid;
		border-top: 1px solid;
		border-bottom: 1px solid;
		text-align: center;
	}
  .border-b {
    border-bottom: 1px solid;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
	li:last-child {
		border-right: 1px solid;
	}
	.el-input__inner {
		border-color: transparent;
	}
	.el-input__inner:focus {
		border-color: transparent;
	}
}
</style>
