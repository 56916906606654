<template>
  <div class="uploadImage">
    <div>
      <el-upload
        action="#"
        :class="{hideUpload: hideUploadEdit}"
        class="avatar-uploader"
        name="image"
        list-type="picture-card"
        :file-list="imageList"
        :limit="1"
        :auto-upload="false"
        :on-change="onChange"
      >
        <i slot="default" class="el-icon-plus" />
        <div slot="file" slot-scope="{file}" v-if="file.url"  class="avatarDiv">
          <img class="el-upload-list__item-thumbnail avatar" :src="file.url" alt="">
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in" />
            </span>
            <span class="el-upload-list__item-delete" @click="handleDownload(file)">
              <i class="el-icon-download" />
            </span>
            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete" />
            </span>
          </span>
        </div>
      </el-upload>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <div style="color: #aaa; font-size: 13px; line-height: 18px; margin-top: 10px;">
      {{ promptText ? promptText : $t('message.iamgeNote') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  components: { },
  props: {
    value: {
      type: [Object, String],
      default: null
    },
    promptText: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      imageList: []
    }
  },
  computed: {
    hideUploadEdit () {
      return this.imageList.length >= 1
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.imageList = []
          return
        }
        if (typeof newVal === 'string') {
          this.imageList = [{
            url: newVal
          }]
          return
        }
        if (typeof newVal === 'object') {
          this.imageList = [newVal]
        }
      },
      immediate: true
    }
  },
  methods: {
    handleRemove () {
      this.$emit('input', null)
      this.$emit('change', null)
    },
    onChange (file) {
      this.$emit('input', file)
      this.$emit('change', file)
    },
    handlePictureCardPreview (file) { this.dialogImageUrl = file.url; this.dialogVisible = true },
    handleDownload (file) { window.open(file.url, '_blank') }
  }
}
</script>

<style lang="scss">
.uploadImage {
  .hideUpload {
  .el-upload.el-upload--picture-card {
    display: none;
    }
  }
}
  .avatar-uploader  {
    cursor: pointer;
  }
  .avatar-uploader :hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: auto;
    padding: 0;
    margin: 0;
    display: inline;
  }
  .avatarDiv {
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 150px;
    height: 150px;
  }
</style>
