<template>
  <div class="alterForm">
    <el-form :label-width="labelWidth" :label-position="labelPosition" :model="createDate" ref="alterForm">
      <el-collapse :value="collapseLength">
        <div v-for="(formItem, topIndex) in formData" :key="formItem.name">
          <el-collapse-item  :title="formItem.title" :name="formItem.name"
           v-if="(!formItem.visible || formItem.show) && ((formItem.client && formItem.client.includes(app_name)) || !formItem.client)">
            <template v-for="item in formItem.createDateItem">
              <el-col :xs="24" :sm="24" :md="24" :lg="item.span" :xl="item.span" :key="item.prop"
                v-if="isGetVisible(item) && ((item.Object.client && item.Object.client.includes(app_name)) || !item.Object.client)">
                <el-form-item :label-width="item.Object['label-width'] ? item.Object['label-width'] : null"
                  :label="item.name !== 'array' ? item.label : ''" :prop="item.prop"
                  :required="item.Object.required ? item.Object.required : false" :rules="getRule(item)">
                  <div v-if="item.name === 'input' && item.type !== 'number'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <el-input :type="item.type ? item.type : 'text'" :rows="item.Object.rows ? item.Object.rows : 0"
                      :placeholder="item.placeholder" v-model="createDate[item.prop]"
                      @change="(data) => { return handleFirstChange(data, item) }"
                      :style="item.Object.style ? item.Object.style : '{ width : 100% }'"
                      :maxlength="item.Object.maxlength ? item.Object.maxlength : null"
                      :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false">
                      <template v-if="item.Object.appendVisible" slot="append">{{ item.Object.append }}</template>
                      <el-button v-if="item.Object.appendBtn" slot="append" :icon="item.Object.icon"></el-button>
                    </el-input>
                    <span class="label__wr" v-if="item.Object.explaim">{{ item.Object.explaim }}</span>
                  </div>
                  <div v-if="item.name === 'input' && item.type === 'number'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <el-input :type="item.type ? item.type : 'text'" :rows="item.Object.rows ? item.Object.rows : 0"
                      :placeholder="item.placeholder" v-model.number="createDate[item.prop]"
                      @change="(data) => { return handleFirstChange(data, item) }"
                      :style="item.Object.style ? item.Object.style : '{ width : 100% }'"
                      :maxlength="item.Object.maxlength ? item.Object.maxlength : null"
                      :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false">
                      <template v-if="item.Object.appendVisible" slot="append">{{ item.Object.append }}</template>
                      <el-button v-if="item.Object.appendBtn" slot="append" :icon="item.Object.icon"></el-button>
                    </el-input>
                    <span class="label__wr" v-if="item.Object.explaim">{{ item.Object.explaim }}</span>
                  </div>
                  <el-radio-group v-model="createDate[item.prop]" v-if="item.name === 'group-radio'"
                    :disabled="isBoolean(item.Object['group-disabled']) ? item.Object['group-disabled'] : false"
                    :size="item.Object.size ? item.Object.size : 'small'"
                    :text-color="item.Object['text-color'] ? item.Object['text-color'] : '#ffffff'"
                    :fill="item.Object.fill ? item.Object.fill : '#409EFF'"
                    @change="(data) => { return handleFirstChange(data, item) }">
                    <el-radio v-for="option in item.options"
                      :border="isBoolean(item.Object.border) ? item.Object.border : false"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                      :key="option[item.Object.key ? item.Object.key : 'value']"
                      :label="option[item.Object.value ? item.Object.value : 'value']">
                      {{ option[item.Object.label ? item.Object.label : 'name'] }}
                    </el-radio>
                  </el-radio-group>
                  <p v-if="item.name === 'group-radio' && item.Object.extraText" style="margin: 0">
                    {{ item.Object.extraText }}</p>
                  <template v-if="item.name === 'group-checkbox'">
                    <el-checkbox v-if="isBoolean(item.Object.allCheck) ? item.Object.allCheck : 'false'"
                      :indeterminate="isIndeterminate" v-model="checkAll"
                      @change="(data) => { return handleCheckAllChange(data, item) }">{{ $t('text.selectAll') }}
                    </el-checkbox>
                    <el-checkbox-group v-model="createDate[item.prop]" :min="item.Object.min ? item.Object.min : 0"
                      :max="item.Object.max ? item.Object.max : 999"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                      @change="(data) => { return handleCheckedChange(data, item, createDate[item.prop]) }">
                      <el-checkbox v-for="option in item.options"
                        :key="option[item.Object.key ? item.Object.key : 'value']"
                        :label="option[item.Object.value ? item.Object.value : 'value']"
                        :disabled="isBoolean(option.disabled) ? option.disabled : false">
                        {{ option[item.Object.label ? item.Object.label : 'name'] }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </template>
                  <div v-if="item.name === 'select'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <el-select @change="(data) => { return handleFirstChange(data, item) }"
                      v-model="createDate[item.prop]" :placeholder="item.placeholder"
                      :style="item.Object.style ? item.Object.style : '{ width : 100% }'"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                      :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                      :multiple="isBoolean(item.Object.multiple) ? item.Object.multiple : false"
                      :collapse-tags="item.Object['collapse-tags'] ? item.Object['collapse-tags'] : false"
                      :filterable="isBoolean(item.Object.filterable) ? item.Object.filterable : false"
                      :multiple-limit="item.Object['multiple-limit'] ? item.Object['multiple-limit'] : 0"
                      :loading="item.Object.loading" :loading-text="$t('message.loading')"
                      :reserve-keyword="isBoolean(item.Object['reserve-keyword']) ? item.Object['reserve-keyword'] : false"
                      :default-first-option="isBoolean(item.Object['default-first-option']) ? item.Object['default-first-option'] : false"
                      :remote="isBoolean(item.Object.remote) ? item.Object.remote : false"
                      :remote-method="query => remoteMethod(query, item.Object.api, item, createDate, item.Object.callBack)">
                      <el-option v-for="option in item.options" :key="option[item.Object.key ? item.Object.key : 'value']"
                        :label="option[item.Object.name ? item.Object.name : 'name']"
                        :value="option[item.Object.value ? item.Object.value : 'value']"
                        :disabled="isBoolean(option.disabled) ? option.disabled : false">
                      </el-option>
                    </el-select>
                    <span class="label__wr" v-if="item.Object.explaim">{{ item.Object.explaim }}</span>
                  </div>
                  <div v-if="item.name === 'image'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <importImg  :limit="item.Object.limit ? item.Object.limit : 100"
                                :data="createDate[item.prop]"
                                :Object="item.Object"
                                :name="item.Object.imageName ? item.Object.imageName : 'image_url'"
                                :watchDataVisible="watchImageVisible"
                                @change="(file) => { return handleImageChange(file, item)}"></importImg>
                    <span>{{ item.Object.size ? item.Object.size : '' }}</span>
                  </div>
                  <div v-if="item.name === 'single-image'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <SingleImage v-model="createDate[item.prop]" />
                    <span>{{ item.Object.size ? item.Object.size : '' }}</span>
                  </div>
                  <div v-if="item.name === 'switch'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <el-switch v-model="createDate[item.prop]"
                      @change="(data) => { return handleFirstChange(data, item) }"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                      :active-text="createDate[item.prop] ? '' : item.Object['inactiveNotice']"
                      :active-value="isBoolean(item.Object['active-value']) ? item.Object['active-value'] : true"
                      :inactive-value="isBoolean(item.Object['inactive-value']) ? item.Object['inactive-value'] : false">
                    </el-switch>
                    <span class="label__wr" v-if="item.Object.explaim">{{ item.Object.explaim }}</span>
                  </div>
                  <p v-if="item.Object.bottomNotice" class="botton-notice">{{ item.Object.bottomNotice }}</p>
                  <div v-if="item.name === 'time'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <el-time-picker v-model="createDate[item.prop]"
                      :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                      :placeholder="item.placeholder"
                      :format="item.Object['option-format'] ? item.Object['option-format'] : 'HH:mm'"
                      :value-format="item.Object.format ? item.Object.format : 'HH:mm'"
                      :is-range="isBoolean(item.Object['is-range']) ? item.Object['is-range'] : false">
                    </el-time-picker>
                  </div>
                  <div v-if="item.name === 'checkbox'">
                    <el-checkbox v-model="createDate[item.prop]">
                      {{ 768 > $store.state.documentWidth ? item.placeholder : '' }}
                    </el-checkbox>
                    <span class="label__wr" v-if="item.Object.explaim">{{ item.Object.explaim }}</span>
                  </div>

                  <el-color-picker v-if="item.name === 'color'" v-model="createDate[item.prop]"
                    :show-alpha="isBoolean(item.Object['show-alpha']) ? item.Object['show-alpha'] : false"
                    :color-format="item.Object['color-format'] ? item.Object['color-format'] : 'hex'">
                  </el-color-picker>
                  <div v-if="item.name === 'date'">
                    <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                      :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                    <el-date-picker @change="(data) => { return handleFirstChange(data, item) }"
                      :type="item.type ? item.type : 'date'" :placeholder="item.placeholder"
                      :start-placeholder="item.Object['start-placeholder'] ? item.Object['start-placeholder'] : ''"
                      :end-placeholder="item.Object['end-placeholder'] ? item.Object['end-placeholder'] : ''"
                      v-model="createDate[item.prop]"
                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                      :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                      :format="item.Object.format ? item.Object.format : 'yyyy-MM-dd'"
                      :value-format="item.Object['value-format'] ? item.Object['value-format'] : 'yyyy-MM-dd'">
                    </el-date-picker>
                  </div>
                  <div v-if="item.name === 'quill-editor'">
                    <quill-editor :ref="item.prop" @click.native="handleEditAblequestion(item.prop)" v-model="createDate[item.prop]" :options="quillOption"></quill-editor>
                  </div>
                  <component v-if="item.name === 'component'" :is="item.Object.name" :ref="item.Object.ref" :object="item"
                    :value.sync="createDate[item.prop]" :modelValue="createDate" @isComplete="isComplete"></component>
                  <div v-if="item.name === 'array'">
                    <template v-for="(dataItem, dataIndex) in createDate[item.prop]">
                      <div class="array__top" :key="'dataItem' + dataIndex" v-if="!dataItem[item.Object.delete]">
                        <span>{{ `${item.label}${dataIndex + 1}` }}</span>
                        <div>
                          <el-button icon="el-icon-plus"
                            v-if="lodash.findLastIndex(createDate[item.prop], (item) => !item._destroy) === dataIndex"
                            circle :disabled="item.Object.addFirstDisabled" @click="addFirstData(item.prop, topIndex)">
                          </el-button>
                          <el-button icon="el-icon-delete" circle type="danger"
                            v-show="subFirstBtnVisible(item, createDate[item.prop][dataIndex])"
                            @click="subFirstData(item.prop, dataIndex, item.Object.delete)"></el-button>
                        </div>
                      </div>
                      <div class="arrayBoxBorder" :key="dataItem + dataIndex" v-show="!dataItem[item.Object.delete]">
                        <template v-for="(firstItem, firstTopIndex) in item.createDateFirstItem">
                          <el-col :xs="24" :sm="24" :md="24" :lg="firstItem.span" :xl="firstItem.span"
                            :key="item.prop + firstItem.prop"
                            v-if="isGetFirstVisible(firstItem, { 'prop': item.prop, 'index': dataIndex, 'firstProp': firstItem.prop }) && ((firstItem.Object.client && firstItem.Object.client.includes(app_name)) || !firstItem.Object.client)">
                            <el-form-item :label="firstItem.name !== 'array' ? firstItem.label : ''"
                              :rules="firstItem.Object.rules ? firstItem.Object.rules : null"
                              :prop="`${item.prop}.${dataIndex}.${firstItem.prop}`"
                              :label-width="item.Object['array-label-width'] ? item.Object['array-label-width'] : null">
                              <div v-if="firstItem.name === 'input'">
                                <el-input :type="firstItem.type ? firstItem.type : 'text'"
                                  :rows="firstItem.Object.rows ? firstItem.Object.rows : 0"
                                  :placeholder="firstItem.placeholder"
                                  @change="(data) => { return handleSecChange(data, firstItem, { prop: item.prop, index: dataIndex }) }"
                                  v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                  :style="firstItem.Object.style ? firstItem.Object.style : '{ width : 100% }'"
                                  :maxlength="firstItem.Object.maxlength ? firstItem.Object.maxlength : null"
                                  :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                  :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : !Utils.isNull(createDate[item.prop][dataIndex][firstItem.Object.conditionDisabled]) ? true : false">
                                  <template v-if="firstItem.Object.appendVisible" slot="append">{{ firstItem.Object.append
                                  }}</template>
                                  <el-button type="primary" v-if="firstItem.Object.appendBtn" slot="append"
                                    :icon="firstItem.Object.icon" @click="handleAppend(dataIndex)"></el-button>
                                </el-input>
                                <span class="label__wr" v-if="firstItem.Object.explaim">{{ firstItem.Object.explaim
                                }}</span>
                              </div>
                              <div v-if="firstItem.name === 'select'">
                                <el-select v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                  :placeholder="firstItem.placeholder"
                                  :style="firstItem.Object.style ? firstItem.Object.style : '{ width : 100% }'"
                                  :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : !Utils.isNull(createDate[item.prop][dataIndex][firstItem.Object.conditionDisabled]) ? true : false"
                                  :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                  :multiple="isBoolean(firstItem.Object.multiple) ? firstItem.Object.multiple : false"
                                  :collapse-tags="isBoolean(firstItem.Object['collapse-tags']) ? firstItem.Object['collapse-tags'] : false"
                                  :filterable="isBoolean(firstItem.Object.filterable) ? firstItem.Object.filterable : false"
                                  :multiple-limit="firstItem.Object['multiple-limit'] ? firstItem.Object['multiple-limit'] : 0"
                                  :loading="isBoolean(firstItem.Object.loading) ? firstItem.Object.loading : false"
                                  :loading-text="$t('message.loading')"
                                  :reserve-keyword="isBoolean(firstItem.Object['reserve-keyword']) ? firstItem.Object['reserve-keyword'] : false"
                                  :default-first-option="isBoolean(firstItem.Object['default-first-option']) ? firstItem.Object['default-first-option'] : false"
                                  :remote="isBoolean(firstItem.Object.remote) ? firstItem.Object.remote : false"
                                  :remote-method="query => remoteMethod(query, firstItem.Object.api, firstItem, createDate[item.prop][dataIndex], firstItem.Object.callBack, [item.prop, dataIndex, firstItem.prop], createDate)"
                                  @change="(data) => { return handleSecChange(data, firstItem, { prop: item.prop, index: dataIndex }) }">
                                  <el-option
                                    v-for="option in (optionsGetter ? optionsGetter([item.prop, dataIndex, firstItem.prop], formData, firstItem.options) : firstItem.options)"
                                    :key="option[firstItem.Object.key ? firstItem.Object.key : 'value']"
                                    :label="option[firstItem.Object.name ? firstItem.Object.name : 'name']"
                                    :value="option[firstItem.Object.value ? firstItem.Object.value : 'value']"
                                    :disabled="isBoolean(option.disabled) ? option.disabled : false">
                                  </el-option>
                                </el-select>
                                <span class="label__wr" v-if="firstItem.Object.explaim">{{ firstItem.Object.explaim
                                }}</span>
                              </div>
                              <el-radio-group v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                v-if="firstItem.name === 'group-radio'"
                                :disabled="isBoolean(firstItem.Object['group-disabled']) ? firstItem.Object['group-disabled'] : false"
                                :size="firstItem.Object.size ? firstItem.Object.size : 'small'"
                                :text-color="firstItem.Object['text-color'] ? firstItem.Object['text-color'] : '#ffffff'"
                                :fill="firstItem.Object.fill ? firstItem.Object.fill : '#409EFF'"
                                @change="(data) => { return handleSecChange(data, firstItem, { prop: item.prop, index: dataIndex }) }">
                                <el-radio v-for="option in firstItem.options"
                                  :border="isBoolean(firstItem.Object.border) ? firstItem.Object.border : false"
                                  :disabled="isBoolean(item.Object.disabled) ? firstItem.Object.disabled : false"
                                  :key="option[firstItem.Object.key ? firstItem.Object.key : 'value']"
                                  :label="option[firstItem.Object.value ? firstItem.Object.value : 'value']">
                                  {{ option[firstItem.Object.label ? firstItem.Object.label : 'name'] }}
                                </el-radio>
                              </el-radio-group>
                              <!-- <div v-if="firstItem.name === 'image'">
                                <importImg  :limit="firstItem.Object.limit ? firstItem.Object.limit : 100"
                                            :data="createDate[item.prop][dataIndex][firstItem.prop]"
                                            :Object="firstItem.Object"
                                            :name="firstItem.Object.imageName ? firstItem.Object.imageName : 'image_url'"
                                            :watchDataVisible="false"
                                            @change="(file) => { return handleFirstImageChange(file, item.prop, dataIndex, firstItem.prop, firstItem)}"></importImg>
                                <span>{{firstItem.Object.size ? firstItem.Object.size : ''}}</span>
                              </div> -->
                              <div v-if="firstItem.name === 'image'">
                                <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                                  :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder
                                  }}</span>
                                <importImg  :limit="firstItem.Object.limit ? firstItem.Object.limit : 100"
                                            :data="createDate[item.prop][dataIndex][firstItem.prop]"
                                            :Object="firstItem.Object"
                                            :name="firstItem.Object.imageName ? firstItem.Object.imageName : 'image_url'"
                                            :promptText="firstItem.Object.promptText"
                                            :watchDataVisible="false"
                                            @change="(file) => { return handleFirstImageChange(file, item.prop, dataIndex, firstItem.prop, firstItem)}"></importImg>
                                <span>{{ item.Object.size ? item.Object.size : '' }}</span>
                              </div>
                              <div v-if="firstItem.name === 'single-image'">
                                <span v-if="768 > $store.state.documentWidth" class="af-placeholder"
                                  :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder
                                  }}</span>
                                <SingleImage v-model="createDate[item.prop][dataIndex][firstItem.prop]" />
                                <span>{{ item.Object.size ? item.Object.size : '' }}</span>
                              </div>
                              <el-switch v-if="firstItem.name === 'switch'"
                                :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                :active-value="isBoolean(firstItem.Object['active-value']) ? firstItem.Object['active-value'] : true"
                                :inactive-value="isBoolean(firstItem.Object['inactive-value']) ? firstItem.Object['inactive-value'] : false">
                              </el-switch>
                              <el-time-picker v-if="firstItem.name === 'time'"
                                :is-range="isBoolean(firstItem.Object['is-range']) ? firstItem.Object['is-range'] : false"
                                :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                :placeholder="firstItem.placeholder"
                                :format="firstItem.Object['option-format'] ? firstItem.Object['option-format'] : 'HH:mm'"
                                :value-format="firstItem.Object.format ? firstItem.Object.format : 'HH:mm'">
                              </el-time-picker>
                              <el-checkbox v-if="firstItem.name === 'checkbox'"
                                :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                v-model="createDate[item.prop][dataIndex][firstItem.prop]">
                                {{ 768 > $store.state.documentWidth ? firstItem.placeholder : '' }}
                              </el-checkbox>
                              <el-color-picker v-if="firstItem.name === 'color'"
                                :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                :show-alpha="isBoolean(firstItem.Object['show-alpha']) ? firstItem.Object['show-alpha'] : false"
                                :color-format="firstItem.Object['color-format'] ? firstItem.Object['color-format'] : 'hex'">
                              </el-color-picker>
                              <el-date-picker v-if="firstItem.name === 'date'"
                                @change="(data) => { return handleFirstChange(data, firstItem, createDate, { 'prop': item.prop, 'index': dataIndex, 'firstProp': firstItem.prop }) }"
                                :type="firstItem.type ? firstItem.type : 'date'" :placeholder="firstItem.placeholder"
                                :start-placeholder="firstItem.Object['start-placeholder'] ? firstItem.Object['start-placeholder'] : ''"
                                :end-placeholder="firstItem.Object['end-placeholder'] ? firstItem.Object['end-placeholder'] : ''"
                                v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                :format="firstItem.Object.format ? firstItem.Object.format : 'yyyy-MM-dd'"
                                :value-format="firstItem.Object['value-format'] ? firstItem.Object['value-format'] : 'yyyy-MM-dd'">
                              </el-date-picker>
                              <component v-if="firstItem.name === 'component'" :is="firstItem.Object.name"
                                :object="firstItem" :value.sync="createDate[item.prop][dataIndex][firstItem.prop]">
                              </component>
                              <div v-if="firstItem.name === 'array'">
                                <template
                                  v-for="(dataSeconedItem, dataSeconedIndex) in createDate[item.prop][dataIndex][firstItem.prop]">
                                  <div class="array__top" :key="'dataSeconedItem' + dataSeconedIndex"
                                    v-if="!dataSeconedItem[firstItem.Object.delete]">
                                    <span>{{ `${firstItem.label}${dataSeconedIndex + 1}` }}</span>
                                    <div>
                                      <el-button icon="el-icon-plus"
                                        v-if="lodash.findLastIndex(createDate[item.prop][dataIndex][firstItem.prop], (secitem) => !secitem._destroy) === dataSeconedIndex"
                                        circle
                                        @click="addSeconedData(item.prop, firstTopIndex, dataIndex, firstItem.prop, topIndex)">
                                      </el-button>
                                      <el-button icon="el-icon-delete" circle type="danger"
                                        @click="subSeconedData(item.prop, dataIndex, firstItem.prop, dataSeconedIndex, firstItem.Object.delete)">
                                      </el-button>
                                    </div>
                                  </div>
                                  <div class="arrayBoxBorder" :key="dataSeconedItem + dataSeconedIndex"
                                    v-show="!dataSeconedItem[firstItem.Object.delete]">
                                    <el-col v-for="seconedItem in firstItem.createDateFirstItem" :xs="24" :sm="24"
                                      :md="24" :lg="seconedItem.span" :xl="seconedItem.span"
                                      :key="firstItem.prop + seconedItem.prop">
                                      <el-form-item :rules="seconedItem.Object.rules ? seconedItem.Object.rules : null"
                                        :label="seconedItem.label"
                                        :prop="`${item.prop}.${dataIndex}.${firstItem.prop}.${dataSeconedIndex}.${seconedItem.prop}`"
                                        :label-width="firstItem.Object['array-label-width'] ? firstItem.Object['array-label-width'] : null"
                                        v-show="!seconedItem.Object.visible || (seconedItem.Object.visible && isGetSeconedVisible(seconedItem, { 'prop': item.prop, 'index': dataIndex, 'firstProp': firstItem.prop, 'seconedIndex': dataSeconedIndex, 'seconedProp': seconedItem.prop }))">
                                        <el-input v-if="seconedItem.name === 'input'"
                                          :type="seconedItem.type ? seconedItem.type : 'text'"
                                          :rows="seconedItem.Object.rows ? seconedItem.Object.rows : 0"
                                          :placeholder="seconedItem.placeholder"
                                          @change="(data) => { return handleFirstChange(data, seconedItem) }"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                          :style="seconedItem.Object.style ? seconedItem.Object.style : '{ width : 100% }'"
                                          :maxlength="seconedItem.Object.maxlength ? seconedItem.Object.maxlength : null"
                                          :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                          :disabled="isBoolean(seconedItem.Object.disabled) ? seconedItem.Object.disabled : false">
                                          <template v-if="seconedItem.Object.appendVisible" slot="append">{{
                                              seconedItem.Object.append
                                          }}</template>
                                        </el-input>
                                        <el-select v-if="seconedItem.name === 'select'"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                          :placeholder="seconedItem.placeholder"
                                          :style="seconedItem.Object.style ? seconedItem.Object.style : '{ width : 100% }'"
                                          :disabled="isBoolean(seconedItem.Object.disabled) ? seconedItem.Object.disabled : false"
                                          :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                          :multiple="isBoolean(seconedItem.Object.multiple) ? seconedItem.Object.multiple : false"
                                          :collapse-tags="isBoolean(seconedItem.Object['collapse-tags']) ? seconedItem.Object['collapse-tags'] : false"
                                          :filterable="isBoolean(seconedItem.Object.filterable) ? seconedItem.Object.filterable : false"
                                          :multiple-limit="seconedItem.Object['multiple-limit'] ? seconedItem.Object['multiple-limit'] : 0"
                                          :loading="isBoolean(seconedItem.Object.loading) ? seconedItem.Object.loading : false"
                                          :loading-text="$t('message.loading')"
                                          :reserve-keyword="isBoolean(seconedItem.Object['reserve-keyword']) ? seconedItem.Object['reserve-keyword'] : false"
                                          :default-first-option="isBoolean(seconedItem.Object['default-first-option']) ? seconedItem.Object['default-first-option'] : false"
                                          :remote="isBoolean(seconedItem.Object.remote) ? seconedItem.Object.remote : false"
                                          :remote-method="remoteMethod"
                                          @change="(data) => { return handleFirstChange(data, seconedItem) }">
                                          <el-option v-for="option in seconedItem.options" :key="option.value"
                                            :label="option.name" :value="option.value"
                                            :disabled="isBoolean(option.disabled) ? option.disabled : false">
                                          </el-option>
                                        </el-select>
                                        <!-- <div v-if="seconedItem.name === 'image'">
                                          <importImg  :limit="seconedItem.Object.limit ? seconedItem.Object.limit : 100"
                                                      :data="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                      :Object="seconedItem.Object"
                                                      :name="seconedItem.Object.imageName ? seconedItem.Object.imageName : 'image_url'"
                                                      :watchDataVisible="false"
                                                      @change="(file) => { return handleSeconedImageChange(file, item.prop, dataIndex, firstItem.prop, dataSeconedIndex, seconedItem.prop, seconedItem)}"></importImg>
                                          <span>{{seconedItem.Object.size ? seconedItem.Object.size : ''}}</span>
                                        </div> -->
                                        <div v-if="seconedItem.name === 'image'">
                                          <span v-if="768 > $store.state.documentWidth" class="af-placeholder" :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                                          <importImg  :limit="seconedItem.Object.limit ? seconedItem.Object.limit : 100"
                                                    :data="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                    :Object="seconedItem.Object"
                                                    :name="seconedItem.Object.imageName ? seconedItem.Object.imageName : 'image_url'"
                                                    :watchDataVisible="false"
                                                    @change="(file) => { return handleSeconedImageChange(file, item.prop, dataIndex, firstItem.prop, dataSeconedIndex, seconedItem.prop, seconedItem)}"></importImg>
                                          <span>{{ item.Object.size ? item.Object.size : '' }}</span>
                                        </div>
                                        <div v-if="seconedItem.name === 'single-image'">
                                          <span v-if="768 > $store.state.documentWidth" class="af-placeholder" :class="{ 'af-placeholder-hidden': !createDate[item.prop] }">{{ item.placeholder }}</span>
                                          <SingleImage
                                            v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]" />
                                          <span>{{ item.Object.size ? item.Object.size : '' }}</span>
                                        </div>
                                        <el-switch v-if="seconedItem.name === 'switch'"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                          :active-value="isBoolean(seconedItem.Object['active-value']) ? seconedItem.Object['active-value'] : true"
                                          :inactive-value="isBoolean(seconedItem.Object['inactive-value']) ? seconedItem.Object['inactive-value'] : false">
                                        </el-switch>
                                        <el-time-picker v-if="seconedItem.name === 'time'"
                                          :is-range="isBoolean(seconedItem.Object['is-range']) ? seconedItem.Object['is-range'] : false"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                          :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                          :placeholder="seconedItem.placeholder"
                                          :format="seconedItem.Object['option-format'] ? seconedItem.Object['option-format'] : 'HH:mm'"
                                          :value-format="seconedItem.Object.format ? seconedItem.Object.format : 'HH:mm'">
                                        </el-time-picker>
                                        <el-checkbox v-if="seconedItem.name === 'checkbox'"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]">
                                          {{ seconedItem.placeholder }}</el-checkbox>
                                        <el-color-picker v-if="seconedItem.name === 'color'"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                          :show-alpha="isBoolean(seconedItem.Object['show-alpha']) ? seconedItem.Object['show-alpha'] : false"
                                          :color-format="seconedItem.Object['color-format'] ? seconedItem.Object['color-format'] : 'hex'">
                                        </el-color-picker>
                                        <el-date-picker v-if="seconedItem.name === 'date'"
                                          :type="seconedItem.type ? seconedItem.type : 'date'"
                                          :placeholder="seconedItem.placeholder"
                                          :start-placeholder="seconedItem.Object['start-placeholder'] ? seconedItem.Object['start-placeholder'] : ''"
                                          :end-placeholder="seconedItem.Object['end-placeholder'] ? seconedItem.Object['end-placeholder'] : ''"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                          :disabled="isBoolean(seconedItem.Object.disabled) ? seconedItem.Object.disabled : false"
                                          :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                          :format="seconedItem.Object.format ? seconedItem.Object.format : 'yyyy-MM-dd'"
                                          :value-format="seconedItem.Object['value-format'] ? seconedItem.Object['value-format'] : 'yyyy-MM-dd'">
                                        </el-date-picker>
                                        <component v-if="seconedItem.name === 'component'" :is="seconedItem.Object.name"
                                          :object="seconedItem"
                                          :value.sync="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]">
                                        </component>
                                      </el-form-item>
                                    </el-col>
                                    <!-- <el-col :span="24">
                                      <el-button type="danger" @click="subSeconedData(item.prop, dataIndex, firstItem.prop, dataSeconedIndex, firstItem.Object.delete)">{{$t('button.delete')}}</el-button>
                                    </el-col> -->
                                  </div>
                                </template>
                                <!-- <el-form-item style="display: inline-table;">
                                  <el-button icon="el-icon-plus" @click="addSeconedData(item.prop, firstTopIndex, dataIndex, firstItem.prop)">{{ $t('button.add') }}</el-button>
                                </el-form-item> -->
                                <div class="array__top array__bottom"
                                  v-if="createDate[item.prop][dataIndex][firstItem.prop] && createDate[item.prop][dataIndex][firstItem.prop].length === 0">
                                  <span>{{ firstItem.label }}</span>
                                  <div>
                                    <el-button icon="el-icon-plus" circle
                                      @click="addSeconedData(item.prop, firstTopIndex, dataIndex, firstItem.prop, topIndex)">
                                    </el-button>
                                  </div>
                                </div>
                              </div>
                            </el-form-item>
                          </el-col>
                        </template>
                        <!-- <el-col :span="24">
                          <el-button type="danger" v-show="subFirstBtnVisible(item, dataIndex)" @click="subFirstData(item.prop, dataIndex, item.Object.delete)">{{$t('button.delete')}}</el-button>
                        </el-col> -->
                      </div>
                    </template>
                    <!-- <el-form-item style="display: inline-table;" > -->
                    <div class="array__top array__bottom" v-if="createDate[item.prop].length === 0">
                      <span>{{ item.label }}</span>
                      <div>
                        <el-button icon="el-icon-plus" circle :disabled="item.Object.addFirstDisabled"
                          @click="addFirstData(item.prop, topIndex)"></el-button>
                      </div>
                    </div>
                    <!-- </el-form-item> -->
                  </div>
                </el-form-item>
              </el-col>
            </template>
          </el-collapse-item>
        </div>
      </el-collapse>
      <!-- <el-row v-if="!customeButton" type="flex" justify="center"> -->
      <!-- <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button> -->
      <!-- </el-row> -->
      <!-- <el-row v-else type="flex" justify="center">
          <slot name="customeButton" :data="createDate"></slot>
        </el-row> -->
    </el-form>
    <!-- <el-row>
      <el-col :span="24"> -->
    <div v-if="!customeButton" class="footer__btn">
      <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
      <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button>
    </div>
    <el-row v-else type="flex" justify="center">
      <slot name="customeButton" :data="createDate"></slot>
    </el-row>
    <!-- </el-col>
    </el-row> -->
  </div>
</template>

<script>
import importImg from '@/components/importImg'
import Utils from '@/utils/Utils'
import lodash from 'lodash'
import moment from 'moment'
// import UploadImage from '@/components/UploadImage/index'
import SingleImage from '@/components/UploadImage/SingleImage.vue'
import quillConfig from '@/utils/quill-config'
import PriceTier from '@/components/PriceTier'


export default {
  components: {
    importImg,
    // UploadImage,
    SingleImage,
    PriceTier
  },
  props: {
    labelPosition: {
      type: String,
      default() {
        return 'right'
      }
    },
    labelWidth: {
      type: String,
      default() {
        return '240px'
      }
    },
    watchImageVisible: {
      type: Boolean,
      default() {
        return true
      }
    },
    formData: {
      type: Array,
      default() {
        return []
      }
    },
    collapseLength: {
      type: Array,
      default() {
        return []
      }
    },
    modifyData: {
      type: Object,
      default() {
        return {}
      }
    },
    customeButton: {
      type: Boolean,
      default() {
        return false
      }
    },
    addFirstArray: {
      type: Boolean,
      default() {
        return false
      }
    },
    addSeconedArray: {
      type: Boolean,
      default() {
        return false
      }
    },
    subFirstArray: {
      type: Boolean,
      default() {
        return false
      }
    },
    validateBeforeVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    optionsGetter: {
      type: Function
    }
  },
  watch: {
    modifyData: {
      deep: true,
      immediate: true,
      handler(n) {
        this.$nextTick(() => {
          // 不安全的深拷贝 比如：undefined、Date对象
          // const obj = JSON.parse(JSON.stringify(n))
          const obj = lodash.cloneDeep(n)
          Object.assign(this.createDate, obj)
        })
      }
    },
    cusAttrLen: {
      deep: true,
      handler(newLen, oldLen) {
        if (newLen) {
          const createDateItemLen = this.formData[0].createDateItem.length
          const createDateItemLast = this.formData[0].createDateItem[createDateItemLen - 1]
          if (createDateItemLast.prop === 'custom_attributes') {
            const allOptions = createDateItemLast.createDateFirstItem[0].options
            if (newLen > oldLen || !oldLen) {
              // 对新增的情况校验表单项disable
              const allOpName = allOptions.map(attItem => attItem.name)
              this.createDate.custom_attributes.forEach((cusItem) => {
                if (cusItem.name && !allOpName.includes(cusItem.name)) {
                  cusItem.disabled = true
                }
              })
            } else {
              // 对删除情况取消下拉选项禁用
              const namesSelected = this.createDate.custom_attributes.map(attItem => attItem.name)
              allOptions.forEach(opItem => {
                if (namesSelected.includes(opItem.name)) {
                  opItem.disabled = true
                } else {
                  opItem.disabled = false
                }
              })
            }
          }
        }
      }
    }
  },
  computed: {
    cusAttrLen() {
      return this.createDate.custom_attributes && this.createDate.custom_attributes.length
    }
  },
  data() {
    return {
      alterData: {},
      createDate: {},
      lodash: lodash,
      recordData: null,
      recordSecData: null,
      recordThrData: null,
      checkAll: false,
      isIndeterminate: true,
      app_name: process.env.VUE_APP_NAME,
      Utils,
      quillOption: quillConfig,
      qillInit: true,
    }
  },
  created() {
    this.init()
  },
  mounted() {
      //包含富文本组件就先取消获取焦点,通过点击时间重新获取
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      formNextData.forEach(res => {
        res.createDateItem.forEach(firstRes => {
          if(firstRes.name == 'quill-editor'){
            this.$refs[firstRes.prop][0].quill.enable(false)
          }
        })
      })
  },
  methods: {
    handleEditAblequestion(prop){
      if (this.qillInit) {
        const formNextData = JSON.parse(JSON.stringify(this.formData))
        formNextData.forEach(res => {
          res.createDateItem.forEach(firstRes => {
            if(firstRes.name == 'quill-editor'){
              this.$refs[firstRes.prop][0].quill.enable(true)
            }
          })
        })
        this.qillInit = false
        this.$refs[prop][0].quill.focus()
      }
    },
    getRule(item) {
      if (item.Object.rules) {
        return item.Object.rules
      } else if (item.Object.validator) {
        if (item.Object.validatorName === 'start') {
          return [
            {
              message: 'time',
              validator: this.pickerOptionsStart(item)
            }
          ]
        }
        if (item.Object.validatorName === 'end') {
          return [
            {
              message: 'time',
              validator: this.pickerOptionsStart(item)
            }
          ]
        }
      }
    },
    pickerOptionsStart(item) {
      // const $this = this
      // if (item.Object.validatorType === 'time') {
      //   if (Utils.isNull($this.createDate[item.prop]) && Utils.isNull($this.createDate[item.Object.validator])) {

      //   } else  {

      //   }
      // return moment(this.createDate[item.prop].toString).valueOf() < moment(this.createDate[item.Object.validator]).valueOf()
      // }
    },
    pickerOptionsEnd(item) {

    },
    getArrayBtnVisible(prop, index) {
      // console.log(this.createDate[prop].reverse())
      // const cindex = this.createDate[prop].reverse().findIndex(item => !item._destroy)
      // if (cindex === index) return true
    },
    // 控制一层删除按钮显示
    subFirstBtnVisible(item, data) {
      if (!Utils.isNull(item.Object.conditionDelete) && !Utils.isNull(data[item.Object.conditionDelete])) return false
      else return true
    },
    isGetVisible(data) {
      if (data.Object.visibleChange && data.Object.visibleChange.length > 0) {
        const flag = data.Object.visibleChange.every(item => {
          const key = Object.keys(item)[0]
          if (Utils.isNull(this.createDate[key])) {
            return false
          }
          if (item[key] instanceof Array && (item[key].includes(this.createDate[key]) || item[key].includes('_All'))) {
            return true
          } else if (!(item[key] instanceof Array) && this.createDate[key].toString() === item[key].toString()) {
            return true
          } else {
            return false
          }
        })
        return flag
      } else if (data.Object.visibleExits && this.isNotNull(this.createDate[data.Object.visible])) {
        return true
      } else if (data.Object.visibleExits) {
        return false
      } else if (Utils.isNull(data.Object.visibleValue) && this.isNotNull(this.createDate[data.Object.visible])) {
        return true
      } else if (this.createDate[data.Object.visible] === null || this.createDate[data.Object.visible] === undefined) {
        return true
      } else if(!(this.createDate[data.Object.visible] instanceof Array)){
        return data.Object.visibleValue.toString().split(',').includes(this.createDate[data.Object.visible].toString())
      } else if ((this.createDate[data.Object.visible] instanceof Array) ) {
        return this.createDate[data.Object.visible].includes(data.Object.visibleValue)
      } else {
        return true
      }
    },
    isGetFirstVisible(data, obj) {
      // this.isGetVisible(data)
      if (data.Object.visibleChange && data.Object.visibleChange.length > 0) {
        const flag = data.Object.visibleChange.every(item => {
          const key = Object.keys(item)[0]
          if (Object.keys(this.createDate).includes(key)) {
            if (Utils.isNull(this.createDate[key])) {
              return false
            }
            if (item[key] instanceof Array && (item[key].includes(this.createDate[key]) || item[key].includes('_All'))) {
              return true
            } else if (!(item[key] instanceof Array) && this.createDate[key].toString() === item[key].toString()) {
              return true
            } else {
              return false
            }
          } else {
            if (Utils.isNull(this.createDate[obj.prop][obj.index][key])) {
              return false
            }
            if (item[key] instanceof Array && (item[key].includes(this.createDate[obj.prop][obj.index][key]) || item[key].includes('_All'))) {
              return true
            } else if (!(item[key] instanceof Array) && this.createDate[obj.prop][obj.index][key].toString() === item[key].toString()) {
              return true
            } else {
              return false
            }
          }
        })
        return flag
      } else {
        return true
      }
      // if (Utils.isNull(item.Object.visibleValue) && (this.isNotNull(this.createDate[item.Object.visible]) || this.isNotNull(this.createDate[object.prop][object.index][item.Object.visible]))) {
      //   return true
      // }
      // let bool_string = null
      // if (this.createDate[item.Object.visible]) {
      //   if (this.createDate[item.Object.visible] === null || this.createDate[item.Object.visible] === undefined) {
      //     bool_string = false
      //   } else {
      //     bool_string = !(this.createDate[item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[item.Object.visible].toString())
      //   }
      // } else {
      //   if (this.createDate[object.prop][object.index][item.Object.visible] === null || this.createDate[object.prop][object.index][item.Object.visible] === undefined) {
      //     bool_string = false
      //   } else {
      //     bool_string = !(this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[object.prop][object.index][item.Object.visible].toString())
      //   }
      // }
      // let bool_array = null
      // if (this.createDate[item.Object.visible]) {
      //   bool_array = (this.createDate[item.Object.visible] instanceof Array) && this.createDate[item.Object.visible].includes(item.Object.visibleValue)
      // } else {
      //   bool_array = (this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].includes(item.Object.visibleValue)
      // }
      // const bool_string = !(this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].toString().split(',').includes(item.Object.visibleValue.toString())
      // const bool_array = (this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].includes(item.Object.visibleValue)
      // return bool_string || bool_array
    },
    isGetSeconedVisible(data, obj) {
      if (data.Object.visibleChange && data.Object.visibleChange.length > 0) {
        const flag = data.Object.visibleChange.every(item => {
          const key = Object.keys(item)[0]
          if (Object.keys(this.createDate).includes(key)) {
            if (Utils.isNull(this.createDate[key])) {
              return false
            }
            if (item[key] instanceof Array && (item[key].includes(this.createDate[key]) || item[key].includes('_All'))) {
              return true
            } else if (!(item[key] instanceof Array) && this.createDate[key].toString() === item[key].toString()) {
              return true
            } else {
              return false
            }
          } else if (Object.keys(this.createDate[obj.prop][obj.index]).includes(key)) {
            if (Utils.isNull(this.createDate[obj.prop][obj.index][key])) {
              return false
            }
            if (item[key] instanceof Array && (item[key].includes(this.createDate[obj.prop][obj.index][key]) || item[key].includes('_All'))) {
              return true
            } else if (!(item[key] instanceof Array) && this.createDate[obj.prop][obj.index][key].toString() === item[key].toString()) {
              return true
            } else {
              return false
            }
          } else {
            if (Utils.isNull(this.createDate[obj.prop][obj.index][obj.firstProp][obj.seconedIndex][key])) {
              return false
            }
            if (item[key] instanceof Array && (item[key].includes(this.createDate[obj.prop][obj.index][obj.firstProp][obj.seconedIndex][key]) || item[key].includes('_All'))) {
              return true
            } else if (!(item[key] instanceof Array) && this.createDate[obj.prop][obj.index][obj.firstProp][obj.seconedIndex][key].toString() === item[key].toString()) {
              return true
            } else {
              return false
            }
          }
        })
        return flag
      } else {
        return true
      }
      // if (Utils.isNull(item.Object.visibleValue) && (this.isNotNull(this.createDate[item.Object.visible]) || this.isNotNull(this.createDate[object.prop][object.index][item.Object.visible]) || this.isNotNull(this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible]))) {
      //   return true
      // }
      // let bool_string = null
      // if (this.createDate[item.Object.visible]) {
      //   if (this.createDate[item.Object.visible] === null || this.createDate[item.Object.visible] === undefined) {
      //     bool_string = false
      //   } else {
      //     bool_string = !(this.createDate[item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[item.Object.visible].toString())
      //   }
      // } else if (this.createDate[object.prop][object.index][item.Object.visible]) {
      //   if (this.createDate[object.prop][object.index][item.Object.visible] === null || this.createDate[object.prop][object.index][item.Object.visible] === undefined) {
      //     bool_string = false
      //   } else {
      //     bool_string = !(this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[object.prop][object.index][item.Object.visible].toString())
      //   }
      // } else {
      //   if (this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] === null || this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] === undefined) {
      //     bool_string = false
      //   } else {
      //     bool_string = !(this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible].toString())
      //   }
      // }
      // let bool_array = null
      // if (this.createDate[item.Object.visible]) {
      //   bool_array = (this.createDate[item.Object.visible] instanceof Array) && this.createDate[item.Object.visible].includes(item.Object.visibleValue)
      // } else if (this.createDate[object.prop][object.index][item.Object.visible]) {
      //   bool_array = (this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].includes(item.Object.visibleValue)
      // } else {
      //   bool_array = (this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible].includes(item.Object.visibleValue)
      // }
      // return bool_string || bool_array
    },
    isBoolean(data) {
      if (data || typeof data === 'boolean') return true
      else return false
    },
    isNotNull(data) {
      if (data && ((data instanceof Array && data.length > 0) || (!(data instanceof Array) && data !== ''))) {
        return true
      } else {
        return false
      }
    },
    init() {
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      formNextData.forEach(res => {
        // if ((res.Object.client && res.Object.client.includes(this.app_name)) || !res.Object.client) {
        // this.$set(this.createDate, res.prop, res.value || res.value === false || res.value === 0 ? res.value : '')
        res.createDateItem.forEach(firstRes => {
          if ((firstRes.Object.client && firstRes.Object.client.includes(this.app_name)) || !firstRes.Object.client) {
            this.$set(this.createDate, firstRes.prop, firstRes.value || firstRes.value === false || firstRes.value === 0 ? firstRes.value : '')
            if (firstRes.name === 'array') {
              firstRes.createDateFirstItem.forEach(secondRes => {
                if ((secondRes.Object.client && secondRes.Object.client.includes(this.app_name)) || !secondRes.Object.client) {
                  this.$set(this.createDate[firstRes.prop], secondRes.prop, secondRes.value || secondRes.value === false || secondRes.value === 0 ? secondRes.value : '')
                }
              })
            }
          }
        })
        // }
      })
      console.log(this.createDate)
    },
    handleFirstChange(data, item) {
      console.log('handleFirstChange==============>', this.createDate, data, item)
      // 切换outlet_id时需要清空预设的自定义属性
      if (item && item.prop === 'outlet_id') {
        this.createDate.custom_attributes = this.createDate.custom_attributes.filter(list => {
          return !list.ownAttri
        })
        // 情况sku信息
        this.createDate.product_skus.map(skuItem => {
          skuItem.outlet_sku_id = null
          skuItem.cost_price = ''
          skuItem.price = ''
          skuItem.sku_id = null
        })
      }

      if (!item.Object.nextChange) return
      if (item.Object.isAll && !this.recordData && data.length > 1 && data.includes(item.Object.isAllValue)) {
        const number = this.createDate[item.prop].indexOf(item.Object.isAllValue)
        this.createDate[item.prop].splice(number, 1)
      } else if (item.Object.isAll && data.length === 1 && data.includes(item.Object.isAllValue)) {
        this.createDate[item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && !this.recordData.includes(item.Object.isAllValue) && data.includes(item.Object.isAllValue)) {
        this.createDate[item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && this.recordData.includes(item.Object.isAllValue && data.includes(item.Object.isAllValue))) {
        const number = this.createDate[item.prop].indexOf(item.Object.isAllValue)
        this.createDate[item.prop].splice(number, 1)
      }
      this.recordData = data
      this.$emit('handleChange', data, item.Object.nextChangeProp, item, this.createDate)
    },
    handleSecChange(data, item, object) {
      console.log('handleSecChange==============>', this.createDate)
      if (!item.Object.nextChange) return
      if (object.prop === 'custom_attributes' && this.createDate.custom_attributes && this.createDate.custom_attributes.length) {
        const namesSelected = this.createDate.custom_attributes.map(attItem => attItem.name)
        item.options.forEach(opItem => {
          if (namesSelected.includes(opItem.name)) {
            opItem.disabled = true
          } else {
            opItem.disabled = false
          }
        })
        const selectItem = item.options.find(opItem => opItem.name === data)
        this.createDate.custom_attributes[object.index].custom_attribute_id = selectItem ? selectItem.id : ''
      }
      if (item.Object.isAll && !this.recordSecData && data.length > 1 && data.includes(item.Object.isAllValue)) {
        const number = this.createDate[object.prop][object.index][item.prop].indexOf(item.Object.isAllValue)
        this.createDate[object.prop][object.index][item.prop].splice(number, 1)
      } else if (item.Object.isAll && data.length === 1 && data.includes(item.Object.isAllValue)) {
        this.createDate[object.prop][object.index][item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && !this.recordSecData.includes(item.Object.isAllValue) && data.includes(item.Object.isAllValue)) {
        this.createDate[object.prop][object.index][item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && this.recordSecData.includes(item.Object.isAllValue && data.includes(item.Object.isAllValue))) {
        const number = this.createDate[object.prop][object.index][item.prop].indexOf(item.Object.isAllValue)
        this.createDate[object.prop][object.index][item.prop].splice(number, 1)
      }
      this.recordSecData = data
      this.$emit('handleChange', data, item.Object.nextChangeProp, item, this.createDate, object)
    },
    subFirstData(prop, index, mark) {
      if (this.subFirstArray) {
        this.$emit('handleSubFirstArray', this.createDate, { prop, index, mark })
        return
      }
      this.createDate[prop].splice(index, 1)
      this.$emit('subfirst')
    },
    addFirstData(prop, collapseIndex) {
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      const obj = formNextData[collapseIndex].createDateItem.filter(item => item.prop === prop)[0]
      this.alterData = {}
      obj.createDateFirstItem.forEach(res => {
        if ((res.Object.client && res.Object.client.includes(this.app_name)) || !res.Object.client) {
          this.$set(this.alterData, res.prop, res.value || res.value === false || res.value === 0 ? res.value : '')
        }
      })
      if (this.addFirstArray) {
        this.$emit('handleAddFirstArray', this.createDate, this.alterData, { prop })
        return
      }
      this.createDate[prop].push(this.alterData)
      // this.$emit('formSubmit', this.createDate)
    },
    subSeconedData(prop, index, firstProp, firstIndex, mark) {
      // if (this.createDate[prop][index][firstProp][firstIndex].id) {
      //   this.$set(this.createDate[prop][index][firstProp][firstIndex], mark, true)
      // } else {
      this.createDate[prop][index][firstProp].splice(firstIndex, 1)
      // }
      // this.$emit('formSubmit', this.createDate)
    },
    addSeconedData(prop, firstTopIndex, firstIndex, firstProp, collapseIndex) {
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      const obj = formNextData[collapseIndex].createDateItem.filter(item => item.prop === prop)[0]
      const nextObj = obj.createDateFirstItem[firstTopIndex]
      this.alterData = {}
      nextObj.createDateFirstItem.forEach(res => {
        if ((res.Object.client && res.Object.client.includes(this.app_name)) || !res.Object.client) {
          this.$set(this.alterData, res.prop, res.value || res.value === false || res.value === 0 ? res.value : '')
        }
      })
      if (this.addSeconedArray) {
        this.$emit('handleAddSeconedArray', this.createDate, this.alterData, { prop, index: firstIndex, firstProp })
        return
      }
      this.createDate[prop][firstIndex][firstProp].push(this.alterData)
      // this.$emit('formSubmit', this.createDate)
    },
    async remoteMethod(query, api, item, dataItem, callBack, keyPaths, createData) {
      if (item.Object.manualRemote) {
        this.$emit('manualRemoteMethod', query, {
          query,
          itemFormData: dataItem,
          createData,
          keyPaths
        })
        return
      }
      if (query !== '') {
        item.Object.loading = true
        if (api) {
          setTimeout(() => {
            api(query).then(res => {
              item.Object.loading = false
              this.$emit('remoteMethod', res, item)
            })
          }, 500)
        }
        if (callBack) {
          item.options = await callBack(query, dataItem)
          item.Object.loading = false
        }
      } else {
        this.$emit('remoteMethod', null, item)
      }
    },
    handleImageChange(data, item) {
      if (data && data.length > 0 && !data[0]._destroy && item.Object.limit === 1) {
        this.createDate[item.prop] = data[0].url
      } else if (data && data.length > 0 && item.Object.limit > 1) {
        this.createDate[item.prop] = data.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy,
            display_order: item.display_order
          }
        })
      } else if (item.Object.limit > 1) {
        this.createDate[item.prop] = []
      } else {
        this.createDate[item.prop] = null
      }
      // if (data && data.length > 0 && item.Object.limit === 1) {
      //   this.createDate[item.prop] = data
      // } else if (data && data.length > 0 && item.Object.limit > 1) {
      //   const ids = []
      //   for (let index = 0; index < data.length; index++) {
      //     const element = data[index]
      //     // if (element.id) { ids.push(element) }
      //     ids.push(element)
      //   }
      //   this.createDate[item.prop] = ids
      // } else if (item.Object.limit > 1) {
      //   this.createDate[item.prop] = []
      // } else {
      //   this.createDate[item.prop] = null
      // }
      // console.log('this.createDate[item.prop]:', this.createDate[item.prop])
    },
    handleSeconedImageChange (data, prop, index, lastProp, secondIndex, secondProp, item) {
      if (data && data.length > 0 && !data[0]._destroy && item.Object.limit === 1) {
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = data[0].url
      } else if (data && data.length > 0 && item.Object.limit > 1) {
        // console.log(data)
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = data.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy,
            display_order: item.display_order
          }
        })
      } else if (item.Object.limit > 1) {
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = []
      } else {
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = null
      }
    },
    handleFirstImageChange(data, prop, index, lastProp, item) {
      if (data && data.length > 0 && !data[0]._destroy && item.Object.limit === 1) {
        this.createDate[prop][index][lastProp] = data[0].url
      } else if (data && data.length > 0 && item.Object.limit > 1) {
        this.createDate[prop][index][lastProp] = data.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy,
            display_order: item.display_order
          }
        })
        // console.log('>>>>2', this.createDate[prop][index][lastProp])
      } else if (item.Object.limit > 1) {
        this.createDate[prop][index][lastProp] = []
      } else {
        this.createDate[prop][index][lastProp] = null
      }
    },
    handleTime(data) {
      if (data.length > 5) {
        data = moment(data).format('HH:mm')
      }
      return Number(data.split(':').join(''))
    },
    handleCompare(data) {
      let flag = true
      if (data.Object.validator) {
        if (Utils.isNull(this.createDate[data.prop]) || Utils.isNull(this.createDate[data.Object.validator])) return true
        if (data.Object.validatorType === 'time') {
          if (this.handleTime(this.createDate[data.prop]) >= this.handleTime(this.createDate[data.Object.validator])) {
            this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
            return flag = false
          } else {
            return flag = true
          }
        }
        if (data.Object.validatorType === 'number') {
          if (!data.Object.validatorMark || data.Object.validatorMark === '>') {
            if (Number(this.createDate[data.prop]) > Number(this.createDate[data.Object.validator])) {
              this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
              return flag = false
            } else {
              return flag = true
            }
          } else if (data.Object.validatorMark === '<=') {
            if (Number(this.createDate[data.prop]) <= Number(this.createDate[data.Object.validator])) {
              this.$message.error(data.Object.validatorStart + this.$t('message.compareNotLessEqual') + data.Object.validatorEnd)
              return flag = false
            } else {
              return flag = true
            }
          } else if(data.Object.validatorMark === '<') {
              if (Number(item[data.prop]) < Number(item[data.Object.validator])) {
                this.$message.error(data.Object.validatorStart + this.$t('message.compareNotLess') + data.Object.validatorEnd)
                return flag =false
              } else {
                return flag =true
              }
          }

        }
        if (data.Object.validatorType === 'date') {
          if (new Date(this.createDate[data.prop]).getTime() >= new Date(this.createDate[data.Object.validator]).getTime()) {
            this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
            return flag = false
          } else {
            return flag = true
          }
        }
        return flag
      } else {
        return true
      }
    },
    handleFirCompare(firstRes, data) {
      if (data.Object.validator) {
        return this.createDate[firstRes.prop].every(item => {
          let flag = true
          if (Utils.isNull(item[data.prop]) || Utils.isNull(item[data.Object.validator])) return true
          if (data.Object.validatorType === 'time') {
            if (this.handleTime(item[data.prop]) >= this.handleTime(item[data.Object.validator])) {
              this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
              return flag = false
            } else {
              return flag = true
            }
          }
          if (data.Object.validatorType === 'number') {
            if (!data.Object.validatorMark || data.Object.validatorMark === '>') {
              if (Number(item[data.prop]) > Number(item[data.Object.validator])) {
                this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
                return flag = false
              } else {
                return flag = true
              }
            } else if (data.Object.validatorMark === '<=') {
              if (Number(item[data.prop]) <= Number(item[data.Object.validator])) {
                this.$message.error(data.Object.validatorStart + this.$t('message.compareNotLessEqual') + data.Object.validatorEnd)
                return flag = false
              } else {
                return flag = true
              }
            } else if(data.Object.validatorMark === '<') {
                if (Number(item[data.prop]) < Number(item[data.Object.validator])) {
                  this.$message.error(data.Object.validatorStart + this.$t('message.compareNotLess') + data.Object.validatorEnd)
                  return flag =false
                } else {
                  return flag =true
                }
            }
          }
          if (data.Object.validatorType === 'date') {
            if (new Date(item[data.prop]).getTime() >= new Date(item[data.Object.validator]).getTime()) {
              this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
              return flag = false
            } else {
              return flag = true
            }
          }
          return flag
        })
      } else {
        return true
      }
    },
    handleSecCompare(firstRes, sec, data) {
      if (data.Object.validator) {
        return this.createDate[firstRes.prop].every(first => {
          return first[sec.prop].every(item => {
            let flag = true
            if (Utils.isNull(item[data.prop]) || Utils.isNull(item[data.Object.validator])) return true
            if (data.Object.validatorType === 'time') {
              if (this.handleTime(item[data.prop]) >= this.handleTime(item[data.Object.validator])) {
                this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
                return flag =false
              } else {
                return flag =true
              }
            }
            if (data.Object.validatorType === 'number') {
              if (!data.Object.validatorMark || data.Object.validatorMark === '>') {
                if (Number(item[data.prop]) > Number(item[data.Object.validator])) {
                  this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
                  return flag =false
                } else {
                  return flag =true
                }
              } else if (data.Object.validatorMark === '<=') {
                if (Number(item[data.prop]) <= Number(item[data.Object.validator])) {
                  this.$message.error(data.Object.validatorStart + this.$t('message.compareNotLessEqual') + data.Object.validatorEnd)
                  return flag =false
                } else {
                  return flag =true
                }
              } else if(data.Object.validatorMark === '<') {
                if (Number(item[data.prop]) < Number(item[data.Object.validator])) {
                  this.$message.error(data.Object.validatorStart + this.$t('message.compareNotLess') + data.Object.validatorEnd)
                  return flag =false
                } else {
                  return flag =true
                }
              }
            }
            if (data.Object.validatorType === 'date') {
              if (new Date(item[data.prop]).getTime() >= new Date(item[data.Object.validator]).getTime()) {
                this.$message.error(data.Object.validatorStart + this.$t('message.compare') + data.Object.validatorEnd)
                return flag =false
              } else {
                return flag =true
              }
            }
            return flag
          })
        })
      } else {
        return true
      }
    },
    handleSubmitClick() {
      if (this.validateBeforeVisible) {
        this.$emit('validateBefore', this.createDate)
      }
      const compare = this.formData.every(res => {
        return res.createDateItem.every((firstRes, firIndex) => {
          return this.handleCompare(firstRes)
          // if (firstRes.name === 'array') {
          //   res.createDateItem[firstRes].createDateItem.every((firstRes, secRes, secIndex) => {
          //     this.handleFirCompare(secRes)
          //     if (secRes.name === 'array') {
          //       res.createDateItem[firstRes].createDateItem.every((tirRes, tirIndex) => {
          //         this.handleSecCompare(tirRes)
          //       })
          //     }
          //   })
          // }
        })
      })
      let compareFir = true
      if (compare) {
        compareFir = this.formData.every(res => {
          return res.createDateItem.every((firstRes, firIndex) => {
            if (firstRes.name === 'array') {
              return res.createDateItem[firIndex].createDateFirstItem.every((secRes, secIndex) => {
                return this.handleFirCompare(firstRes, secRes)
              })
            } else {
              return true
            }
          })
        })
      }

      let compareSec = true
      if (compareFir) {
        compareSec = this.formData.every(res => {
          return res.createDateItem.every((firstRes, firIndex) => {
            if (firstRes.name === 'array') {
              return res.createDateItem[firIndex].createDateFirstItem.every((secRes, secIndex) => {
                if (secRes.name === 'array') {
                  return res.createDateItem[firIndex].createDateFirstItem[secIndex].createDateFirstItem.every(tirRes => {
                    return this.handleSecCompare(firstRes, secRes, tirRes)
                  })
                } else {
                  return true
                }
              })
            } else {
              return true
            }
          })
        })
      }

      if (!(compareFir && compare && compareSec)) return
      this.$refs.alterForm.validate((valid) => {
        if (valid && this.$refs.skus) {
          const isSkuRq = this.$refs.skus[0].handleSubmitClick()
          if (isSkuRq) {
            this.$emit('formSubmit', this.createDate)
          } else {
            this.$message.error(this.$t('message.formIsRequire'))
            return false
          }
          return
        }
        if (valid) {
          this.$emit('formSubmit', this.createDate)
        } else {
          this.$message.error(this.$t('message.formIsRequire'))
          return false
        }
      })
    },
    isComplete(data) {
      this.$emit('isComplete', data, this.createDate)
    },
    handleCancelClick() {
      this.$emit('close')
      this.$router.go(-1)
    },
    // 操作第一层checkbox
    handleCheckAllChange(val, item) {
      const arr = []
      item.options.forEach(res => {
        arr.push(res[item.Object.value ? item.Object.value : 'value'])
      })
      this.createDate[item.prop] = val ? arr : []
      // console.log(data, this.createDate)
      this.isIndeterminate = false
    },
    handleCheckedChange(val, item) {
      const checkedCount = val.length
      this.checkAll = checkedCount === item.options.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < item.options.length
      this.handleFirstChange(val, item)
    },
    switchChanged(value, prop) {
      this.$emit('switchChanged', value, this.createDate, prop)
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.alterForm.resetFields()
      })
    },
    handleAppend(index) {
      this.$emit('handleAppend', index)
    }
  }
}
</script>

<style lang="scss" scope>
.el-collapse-item__header {
  font-size: 16px;
  padding-left: 20px;
  padding-right: 15px;
}

.el-collapse-item__wrap {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 20px solid #f2f2f2;
}

.el-collapse-item {
  &:last-child {
    .el-collapse-item__wrap {
      border-bottom: 1px solid #EBEEF5;
    }
  }
}

.label__wr {
  display: block;
  word-break: break-word;
  line-height: 18px;
  margin-top: 8px;
  font-size: 12px;
  color: #999;
}

.el-form {
  display: inline-block;
  width: 100%;
}

.el-select {
  width: 100%;
}

.el-input {
  width: 100% !important;
}

.arrayBoxBorder {
  border: 1px solid #EBEEF5;
  padding: 12px;
  display: flow-root;
  margin-bottom: 12px;
  border-radius: 0 0 10px 10px;
  // &:last {
  //   margin-bottom: 0 !important;
  // }
}

.el-date-editor--daterange {
  width: 100% !important;
}

.el-date-editor--time {
  width: 100% !important;
}

.array__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(228, 231, 235);
  padding: 10px 8px;
  font-weight: 600;
  font-size: .8rem;
  border-radius: 10px 10px 0 0;
}

.array__bottom {
  border-radius: 10px;
}

.botton-notice {
  margin: 0;
  color: #999;
  font-size: 12px;
  line-height: 14px;
}

.el-checkbox,
.el-checkbox__input {
  white-space: normal;
  // word-break: break-all;
}

.el-date-editor--datarange {
  width: 100% !important;
}

.footer__btn {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

// @media screen and (max-width: 1200px) {
//   grid-template-columns: repeat(2, $var-box-pad-width);
// }
@media screen and (max-width: 768px) {
  .el-form-item__label {
    // display: none;
    // width: 0 !important;
    text-align:left;
    float: none;
    word-break: break-word;
  }

  .el-form-item__content {
    margin-left: 0 !important;
  }
}

.af-placeholder {
  display: block;
  line-height: 18px;
  font-size: 14px;
  color: #606266;
  transition: all .3s;
  margin-bottom: 2px;
}

.af-placeholder-hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(40px);
}
</style>
